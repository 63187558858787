/**
 * icon: menu item icon
 * roles: Indicate under which role the current menu item can be displayed. If this option is not written, it means that the menu item is completely public and displayed under any role
 */

const menuList = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: "cluster",
    roles: ["SuperAdmin", "TenantAdmin"]
  },

  {
    title: "Subscribers",
    path: "/tenants/list",
    icon: "user",
    roles: ["SuperAdmin"]
  },
  {
    title: "Users",
    path: "/users/list",
    icon: "user",
    roles: ["SuperAdmin", "TenantAdmin"]
  },
  {
    title: "Customers",
    path: "/customers/list",
    icon: "usergroup-add",
    roles: ["TenantAdmin"]
  },
  {
    title: "Buildings",
    path: "/buildings/list",
    icon: "home",
    roles: ["TenantAdmin"]
  },
  {
    title: "Floors",
    path: "/floors/list",
    icon: "appstore",
    roles: ["TenantAdmin"]
  },
  {
    title: "Test Results",
    path: "/test-results",
    icon: "file",
    roles: ["SuperAdmin", "TenantAdmin", "TenantUser"]
  },

  // {
  //   title: "Permission",
  //   path: "/permission",
  //   icon: "lock",
  //   children: [
  //     {
  //       title: "权限说明",
  //       path: "/permission/explanation",
  //       roles:["admin"]
  //     },
  //     {
  //       title: "admin页面",
  //       path: "/permission/adminPage",
  //       roles:["admin"]
  //     },
  //     {
  //       title: "guest页面",
  //       path: "/permission/guestPage",
  //       roles:["guest"]
  //     },
  //     {
  //       title: "editor页面",
  //       path: "/permission/editorPage",
  //       roles:["editor"]
  //     },
  //   ],
  // },

];
export default menuList;
