import request from "@/utils/request";
import { API_CONSTANTS } from "../utils/constants";

// login api
export function reqLogin(data) {
  return request({
    url: API_CONSTANTS.LOGIN.AUTH_TOKEN,
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data,
  });
}

// logout api
export function reqLogout(data) {
  return request({
    url: "/logout",
    method: "post",
    data,
  });
}

// get user details api
export function reqUserInfo() {
  return request({
    url: API_CONSTANTS.LOGIN.USER_DETAILS,
    method: "get",
  });
}
