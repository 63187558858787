import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Checkbox, Button, message, Spin } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import DocumentTitle from "react-document-title";
import "./index.less";
import { getUserInfo } from "@/store/actions";
import logo from "@/assets/images/logo.png";
import { SUPER_ADMIN, TENANT_ADMIN, TENANT_USER } from "../../utils/constants";
import { doLogin } from "../../store/actions/auth";

const Login = (props) => {
  const { form, token } = props;
  const { getFieldDecorator } = form;
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const role = useSelector(state => state.auth.role);

  const handleLogin = async (username, password) => {
    // After logging in, send a request and call the interface to obtain user information
    try {
      setLoading(true);
      await dispatch(doLogin(username, password));
      await dispatch(getUserInfo());
      setLoading(false);
      message.success('Login successful');
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 401) {
        message.error(e.response.data.detail);
        return;
      }
      message.error('Failed to login')
    }
  };

  useEffect(() => {
    if (token) {
      if (role === TENANT_ADMIN || role === SUPER_ADMIN) {
        history.push('/dashboard');
      } else if (role === TENANT_USER) {
        history.push('/test-results');
      }
    }
  }, [token, role]);

  const handleSubmit = (event) => {
    // Default behavior for blocking events

    event.preventDefault();

    // Validate all form fields

    form.validateFields((err, values) => {
      // The test was successful

      if (!err) {
        const { username, password } = values;
        handleLogin(username, password);
      } else {
        console.log("检验失败!");
      }
    });
  };


  return (
    <DocumentTitle title={"N-Connect"}>
      <div className="login-container">
        <Form
          onSubmit={handleSubmit}
          className="login-form content"
        >
          <div className="title">
            {/* <h2>N-Connect</h2> */}
            <img src={logo} className="sidebar-logo" alt="logo" />
          </div>
          <Spin spinning={loading} tip="Logging in...">
            <Form.Item>
              {getFieldDecorator("username", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter user name",
                  },
                ],
              })(
                <Input
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Username"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter password",
                  },
                ],
              })(
                <Input
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item name="remember">
              {/* <Checkbox>
                <span className="reminderTxt">Remember me</span>
              </Checkbox> */}
              <a className="login-form-forgot" href="">
                Forgot password
              </a>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Login
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <span>账号 : admin 密码 : 随便填</span>
              <br />
              <span>账号 : editor 密码 : 随便填</span>
              <br />
              <span>账号 : guest 密码 : 随便填</span>
            </Form.Item> */}
          </Spin>
        </Form>
      </div>
    </DocumentTitle>
  );
};

const WrapLogin = Form.create()(Login);

export default connect((state) => state.auth)(
  WrapLogin
);
