import { reqLogin, reqLogout, reqUserInfo } from "@/api/login";
import { setToken, removeToken } from "@/utils/auth";
import * as types from "../action-types";

// api calls
export const doLogin = (username, password) => (dispatch) => {
  const params = new URLSearchParams({ username, password });
  return reqLogin(params)
    .then((response) => {
      const { data } = response;
      if (data) {
        const token = data.access_token;
        dispatch(setUserToken(token));
        setToken(token);
      } else {
        const msg = data.message;
      }
    })
    .catch((error) => {
      throw error
    });
};

export const logout = (token) => (dispatch) => {
  return reqLogout(token)
    .then((response) => {
      const { data } = response;
      if (data.status === 0) {
        dispatch(resetUser());
        removeToken();
      } else {
        const msg = data.message;
      }
    })
    .catch((error) => {
      throw error
    });
};

export const getUserInfo = () => (dispatch) => {
  return reqUserInfo()
    .then((response) => {
      const { data } = response;
      if (data) {
        dispatch(setUserInfo(data));
      } else {
        const msg = data.message;
      }
    })
    .catch((error) => {
      throw error
    });
};

// action creators
export const setUserToken = (token) => {
  return {
    type: types.USER_SET_USER_TOKEN,
    token,
  };
};

export const setUserInfo = (userInfo) => {
  return {
    type: types.USER_SET_USER_INFO,
    payload: userInfo,
  };
};

export const resetUser = () => {
  return {
    type: types.USER_RESET_USER,
  };
};
