import * as types from "../action-types";
import { getToken } from "@/utils/auth";
const intialState = {
  role: null,
  loggedInUser: null,
  token: getToken(),
};
export default function auth(state = intialState, action) {
  switch (action.type) {
    case types.USER_SET_USER_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case types.USER_SET_USER_INFO:
      return {
        ...state,
        role: action.payload.role,
        loggedInUser: action.payload,
      };
    case types.USER_RESET_USER:
      return {};
    default:
      return state;
  }
}
