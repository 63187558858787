export const USER_SET_USER_TOKEN = "USER_SET_USER_TOKEN";
export const USER_SET_USER_INFO = "USER_SET_USER_INFO";
export const USER_RESET_USER = "USER_RESET_USER";

// app
export const APP_TOGGLE_SIDEBAR = "APP_TOGGLE_SIDEBAR";
export const APP_TOGGLE_SETTINGPANEL = "APP_TOGGLE_SETTINGPANEL";
export const APP_SET_TABLES_QUERY_PARAMS = "APP_SET_TABLES_QUERY_PARAMS"
export const APP_RESET_TABLES_QUERY_PARAMS = "APP_RESET_TABLES_QUERY_PARAMS"

// settings
export const SETTINGS_CHANGE_SETTINGS = "SETTINGS_CHANGE_SETTINGS";

// tagsView
export const TAGSVIEW_ADD_TAG = "TAGSVIEW_ADD_TAG";
export const TAGSVIEW_DELETE_TAG = "TAGSVIEW_DELETE_TAG";
export const TAGSVIEW_EMPTY_TAGLIST = "TAGSVIEW_EMPTY_TAGLIST";
export const TAGSVIEW_CLOSE_OTHER_TAGS = "TAGSVIEW_CLOSE_OTHER_TAGS";

// monitor
export const BUG_ADD_BUG = "BUG_ADD_BUG";

//TENANTS
export const TENANTS_SET_TENANTS_LIST = "TENANTS_SET_TENANTS_LIST";

//CUSTOMERS
export const CUSTOMERS_SET_CUSTOMERS_LIST = "CUSTOMERS_SET_CUSTOMERS_LIST";

//BUILDINGS
export const BUILDINGS_SET_BUILDINGS_LIST = "BUILDINGS_SET_BUILDINGS_LIST";

//FLOORS
export const FLOORS_SET_FLOORS_LIST = "FLOORS_SET_FLOORS_LIST";

// USERS
export const USERS_SET_USERS_LIST = "USERS_SET_USERS_LIST";
