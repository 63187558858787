import * as types from "../action-types";
const intialState = {
  tenant: {
    name: "",
    description: "",
    subscription_ends_on: "",
    id: null,
  },
  tenantsList: [],
  totalTenantsCount: null,
};
export default function tenant(state = intialState, action) {
  switch (action.type) {
    case types.TENANTS_SET_TENANTS_LIST:
      return {
        ...state,
        tenantsList: action.payload.data,
        totalTenantsCount: action.payload.total_rows,
      };
    default:
      return state;
  }
}
