import axios from "axios";
import store from "@/store";
import { Modal } from "antd";
import { getToken } from "@/utils/auth";
import { logout } from "@/store/actions";

//创建一个axios示例
const service = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL, // api 的 base_url
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
  timeout: 5000, // request timeout
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (store.getState().auth.token) {
      // 让每个请求携带token-- ['Authorization']为自定义key 请根据实际情况自行修改
      config.headers.Authorization = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => response,
 /**
   * The following notes indicate the request status by customizing the code in the response
   * When the code returns as follows, it means there is a problem with permissions, log out and return to the login page
   * If you want to use xmlhttprequest to identify the status code, the logic can be written in the following error
   * The following code is a sample, please modify it according to your own needs, if you don't need it, you can delete it
   */
  // response => {
  //   const res = response.data
  //   if (res.code !== 20000) {
  //     Message({
  //       message: res.message,
  //       type: 'error',
  //       duration: 5 * 1000
  //     })
  //   // 50008: Illegal token; 50012: Other clients have logged in; 50014: Token has expired;
  //       if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
  //       // Please introduce MessageBox by yourself
  //       // import { Message, MessageBox } from 'element-ui'
  //       MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm to log out', {
  //       confirmButtonText: 're-login',
  //       cancelButtonText: 'Cancel',
  //       type: 'warning'
  //       }).then(() => {
  //       store.dispatch('FedLogOut').then(() => {
  //       location.reload() // To re-instantiate the vue-router object to avoid bugs
  //         })
  //       })
  //     }
  //     return Promise.reject('error')
  //   } else {
  //     return response.data
  //   }
  // },
  // (error) => {
  //   console.log("err" + error); // for debug
  //   const { status } = error.response;
  //   if (status === 403) {
  //     Modal.confirm({
  //       title: "OK to log out?",
  //       content:
  //         "You have been logged out due to inactivity for a long time, you can cancel to stay on this page, or log in again.",
  //       okText: "Sure",
  //       cancelText: "Cancel",
  //       onOk() {
  //         let token = store.getState().auth.token;
  //         store.dispatch(logout(token));
  //       },
  //       onCancel() {
  //         console.log("Cancel");
  //       },
  //     });
  //   }
  //   return Promise.reject(error);
  // }
);

export default service;
