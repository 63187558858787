// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/BG-Nconnect-A.jpeg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-container {\n  height: 100vh;\n  overflow: hidden;\n  position: relative;\n  background: #2d3a4b;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n}\n.login-container > .title {\n  color: #eee;\n  font-size: 26px;\n  font-weight: 400;\n  margin: 0px auto 30px auto;\n  text-align: center;\n  font-weight: bold;\n  letter-spacing: 1px;\n}\n.login-container .sidebar-logo {\n  width: 33%;\n}\n.login-container .login-form-forgot,\n.login-container .reminderTxt {\n  color: white;\n}\n.login-container .spin-wrap {\n  text-align: center;\n  background-color: rgba(255, 255, 255, 0.3);\n  border-radius: 4px;\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n  line-height: 90vh;\n  z-index: 999;\n}\n.login-container .content {\n  position: absolute;\n  background-color: rgba(79, 108, 138, 0.5);\n  left: 80%;\n  top: 50%;\n  width: 320px;\n  padding: 30px 30px 0 30px;\n  transform: translate(-50%, -60%);\n  box-shadow: 0 0 10px 2px rgba(40, 138, 204, 0.16);\n  border-radius: 20px;\n}\n.login-container .content .title {\n  text-align: center;\n  margin: 0 0 30px 0;\n}\n.login-container .content .two-button-wrap {\n  display: flex;\n  justify-content: space-between;\n}\n.login-container .content .row-container {\n  display: flex;\n  justify-content: space-between;\n}\n.login-container .content .user-type-button {\n  width: 45%;\n}\n.login-container .content .login-form-button {\n  width: 100%;\n}\n.login-container .content .login-form-forgot {\n  float: right;\n}\n", ""]);
// Exports
module.exports = exports;
