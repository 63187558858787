import * as types from "../action-types";
const initState = {
  sidebarCollapsed: false,
  settingPanelVisible: false,
  default_query_params: {
    skip: 0,
    limit: 10,
    sort: null,
    inc_rel: false,
  }
};
export default function app(state = initState, action) {
  switch (action.type) {
    case types.APP_TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      };
    case types.APP_TOGGLE_SETTINGPANEL:
      return {
        ...state,
        settingPanelVisible: !state.settingPanelVisible,
      };
    case types.APP_SET_TABLES_QUERY_PARAMS:
      return {
        ...state,
        default_query_params: {
          ...state.default_query_params,
          ...action.payload
        }
      };
    case types.APP_RESET_TABLES_QUERY_PARAMS:
      return {
        ...state,
        default_query_params: {
          skip: 0,
          limit: 10,
          sort: null,
          inc_rel: false,
        }
      };
    default:
      return state;
  }
}
