import * as types from "../action-types";
const intialState = {
  customer: {
    org_name: "",
    address: "",
    email: "",
    country_code: "",
    phone_number: "",
    tenant_id: null,
    id: null,
  },
  customersList: [],
  totalCustomersCount: null,
};
export default function customer(state = intialState, action) {
  switch (action.type) {
    case types.CUSTOMERS_SET_CUSTOMERS_LIST:
      return {
        ...state,
        customersList: action.payload.data,
        totalCustomersCount: action.payload.total_rows,
      };
    default:
      return state;
  }
}
