import Loadable from "react-loadable";
import Loading from "@/components/Loading";
const Dashboard = Loadable({
  loader: () => import(/*webpackChunkName:'Dashboard'*/ "@/views/dashboard"),
  loading: Loading,
});
const TestResults = Loadable({
  loader: () => import(/*webpackChunkName:'Table'*/ "@/views/test-results"),
  loading: Loading,
});
const Error404 = Loadable({
  loader: () => import(/*webpackChunkName:'Error404'*/ "@/views/error/404"),
  loading: Loading,
});

// users
const User = Loadable({
  loader: () => import(/*webpackChunkName:'User'*/ "@/views/users"),
  loading: Loading,
});

const UserForm = Loadable({
  loader: () => import("@/views/users/forms/user-form.jsx"),
  loading: Loading,
});


// Tenants
const Tenants = Loadable({
  loader: () => import("@/views/tenants"),
  loading: Loading,
});
const TenantForm = Loadable({
  loader: () => import("@/views/tenants/forms/tenant-form.jsx"),
  loading: Loading,
});

// customers
const Customer = Loadable({
  loader: () =>
    import(/*webpackChunkName:'list'*/ "@/views/customers"),
  loading: Loading,
});
const CustomerForm = Loadable({
  loader: () =>
    import(
      /*webpackChunkName:'AddEditCustomer'*/ "@/views/customers/forms/customer-form.jsx"
    ),
  loading: Loading,
});

// buildings
const Buildings = Loadable({
  loader: () => import("@/views/buildings"),
  loading: Loading,
});
const BuildingForm = Loadable({
  loader: () => import("@/views/buildings/forms/building-form.jsx"),
  loading: Loading,
});

// Floors
const Floors = Loadable({
  loader: () => import("@/views/floors"),
  loading: Loading,
});
const FloorForm = Loadable({
  loader: () => import("@/views/floors/forms/floor-form.jsx"),
  loading: Loading,
});

export default [
  {
    path: "/dashboard",
    component: Dashboard,
    roles: ["admin", "editor", "guest"],
  },
  { path: "/test-results", component: TestResults, roles: ["admin", "editor"] },
  { path: "/users/list", component: User, roles: ["admin"] },
  {
    path: "/users/add",
    component: UserForm,
    roles: ["admin"],
  },
  {
    path: "/users/edit/:id",
    component: UserForm,
    roles: ["admin"],
  },
  { path: "/customers/list", component: Customer, roles: ["admin"] },
  {
    path: "/customers/add",
    component: CustomerForm,
    roles: ["admin"],
  },
  {
    path: "/customers/edit/:id",
    component: CustomerForm,
    roles: ["admin"],
  },
  { path: "/tenants/list", component: Tenants, roles: ["admin"] },
  {
    path: "/tenants/add",
    component: TenantForm,
    roles: ["admin"],
  },
  {
    path: "/tenants/edit/:id",
    component: TenantForm,
    roles: ["admin"],
  },
  { path: "/buildings/list", component: Buildings, roles: ["admin"] },
  {
    path: "/buildings/add",
    component: BuildingForm,
    roles: ["admin"],
  },
  {
    path: "/buildings/edit/:id",
    component: BuildingForm,
    roles: ["admin"],
  },
  { path: "/floors/list", component: Floors, roles: ["admin"] },
  {
    path: "/floors/add",
    component: FloorForm,
    roles: ["admin"],
  },
  {
    path: "/floors/edit/:id",
    component: FloorForm,
    roles: ["admin"],
  },
  { path: "/error/404", component: Error404 },
];
