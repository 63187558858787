import { combineReducers } from "redux";
import user from "./user";
import tenant from "./tenant";
import customer from "./customer";
import building from "./building";
import floor from "./floor";
import app from "./app";
import auth from "./auth";
import settings from "./settings";
import tagsView from "./tagsView";
import monitor from "./monitor";

export default combineReducers({
  auth,
  user,
  tenant,
  customer,
  building,
  floor,
  app,
  settings,
  tagsView,
  monitor
});
