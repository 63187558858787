export const API_CONSTANTS = {
  LOGIN: {
    AUTH_TOKEN: "auth/token",
    USER_DETAILS: "auth/users/me",
  },
  CUSTOMERS: "customers/",
  USERS: "users/",
  TENANTS: "tenants/",
  BUILDINGS: "buildings/",
  BUILDING_TYPES: "buildingtypes/",
  FLOORS: "floors/",
  COUNTRIES: "countries/",
};

export const ROLES = [
  // "SuperAdmin",
  "TenantAdmin",
  "TenantUser",
];

export const TENANT_ADMIN = 'TenantAdmin'
export const SUPER_ADMIN = 'SuperAdmin'
export const TENANT_USER = 'TenantUser'


export function getFileFromAntdUpload(e) {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};


export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SKIP = 0

export const DEFAULT_PARAMS = {
  skip: DEFAULT_SKIP,
  limit: DEFAULT_PAGE_SIZE,
  inc_rel: true
}

export const GET_ALL_RECORDS_PARAMS = {
  skip: 0,
  limit: -1
}