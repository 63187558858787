import * as types from "../action-types";
const intialState = {
  building: {
    name: "",
    address: "",
    location: "",
    customer_id: null,
    id: null,
  },
  buildingsList: [],
  totalBuildingsCount: null,
};
export default function building(state = intialState, action) {
  switch (action.type) {
    case types.BUILDINGS_SET_BUILDINGS_LIST:
      return {
        ...state,
        buildingsList: action.payload.data,
        totalBuildingsCount: action.payload.total_rows,
      };
    default:
      return state;
  }
}
