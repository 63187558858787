import * as types from "../action-types";
export const toggleSiderBar = () => {
  return {
    type: types.APP_TOGGLE_SIDEBAR
  };
};

export const toggleSettingPanel = () => {
  return {
    type: types.APP_TOGGLE_SETTINGPANEL
  };
};


export const setTableQueryParams = (data) => {
  return {
    type: types.APP_SET_TABLES_QUERY_PARAMS,
    payload: data
  };
};

export const resetTableQueryParams = () => {
  return {
    type: types.APP_RESET_TABLES_QUERY_PARAMS
  };
};