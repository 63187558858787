import * as types from "../action-types";
const intialState = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    country_code: "",
    phone_number: "",
    role: "",
    tenant_id: null,
    id: null,
  },
  usersList: [],
  totalUsersCount: null,
};
export default function user(state = intialState, action) {
  switch (action.type) {
    case types.USERS_SET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload.data,
        totalUsersCount: action.payload.total_rows,
      };
    default:
      return state;
  }
}
