export default {
  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: false,
// If you only want to display the system settings panel in the development environment, but not in the production environment, please open the following line of code  // showSettings: process.env.NODE_ENV === "development",

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,
  
  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: false,
};
