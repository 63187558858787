import * as types from "../action-types";
const intialState = {
  floor: {
    name: "",
    number: "",
    description: "",
    plan_file: "",
    building_id: null,
    id: null,
  },
  floorsList: [],
  totalFloorsCount: null,
};
export default function floor(state = intialState, action) {
  switch (action.type) {
    case types.FLOORS_SET_FLOORS_LIST:
      return {
        ...state,
        floorsList: action.payload.data,
        totalFloorsCount: action.payload.total_rows,
      };
    default:
      return state;
  }
}
